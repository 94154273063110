import { Injectable } from '@angular/core'
import { Observable, Subject, take } from 'rxjs'
import { EnvironmentService } from '../../environments/environment.service'
import newNotification from '../core/home/components/navbar/navbar.component'
import { WebSocketSubject, webSocket } from 'rxjs/webSocket'
import { deserialize } from 'v8'
import { environment } from '../../environments/environment'

@Injectable({
	providedIn: 'root',
})
export class WebSocketService {
	public socket!: WebSocketSubject<string>

	constructor(private envService: EnvironmentService) {}

	public create(url: string) {
		this.socket = webSocket({
			url: environment.websocketUrl,
			deserializer: (msg) => msg.data,
		})

		this.socket.subscribe({
			next: (msg: any) => {
				if (msg === 'init') {
					this.send('id', this.envService.getUserId()!)
				}
				// if the role was changed, the user is logged out
				if (msg === 'logout') {
					this.envService.logout()
				}
			},
		})
	}

	public send(type: string, msg: string) {
		this.socket.next(`{${type}:${msg}}`)
	}
}
