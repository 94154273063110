import {
	HttpClient,
	HttpParams,
	HttpResponse,
	HttpStatusCode,
} from '@angular/common/http'
import { Injectable, LOCALE_ID } from '@angular/core'

import { first, map } from 'rxjs/operators'
import { firstValueFrom, Observable } from 'rxjs'
import { EnvironmentService } from '../../../../environments/environment.service'
import { DatePipe, registerLocaleData } from '@angular/common'
import localePt from '@angular/common/locales/pt'
import { environment } from '../../../../environments/environment'
import { AggregateModel } from '../../../shared/models/aggregate'
import { GenericListWrapper } from '../../../shared/models/common'
import { DriverTable, DriverDetails } from '../../../shared/models/driver'
import { CustomerSupportMail } from '../../../shared/models/email'
import {
	NotificationObject,
	UpdateNotification,
} from '../../../shared/models/notification'
import {
	RequestsModel,
	RequestsModelToAPI,
} from '../../../shared/models/request'
import {
	StopsModel,
	StopTimeTableModel,
	StopTimeTableOnly,
} from '../../../shared/models/stop'
import {
	UsersModel,
	CreateUserModel,
	UpdateUserModel,
	UpdateProfileModel,
	DriverLicenseCategory,
	DriverLicenseCategoryIncoming,
	DriverFiles,
	UserRequestsModel,
	CreateUserTimesheetModel,
	UserTimesheetModel,
	UpdateUserTimesheetModel,
	TimeRange,
} from '../../../shared/models/user'
import {
	SelectedDriverVehicle,
	SelectedDriverTravelHistory,
	VehicleDataModel,
	VehicleFiles,
} from '../../../shared/models/vehicle'
import {
	TimeTable,
	ZoneDriver,
	ZoneDriverInsert,
	ZonesModel,
} from '../../../shared/models/zone'
import { PricesPerKms } from '../../../shared/models/pricesPerKms'
import { SpecialTickets } from '../../../constants/special-tickets.enum'
import {
	OperatorDataModel,
	SelectedOperatorModel,
} from '../../../shared/models/operator'
import { RequestStatus } from '../../../constants/request-status.enum'
import { TripsModel, TripStops } from '../../../shared/models/trip'
import { TripStatus } from '../../../constants/trip-status.enum'

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	constructor(
		private http: HttpClient,
		private envSer: EnvironmentService,
	) {
		registerLocaleData(localePt, 'pt-PT')
	}

	getStops(
		page: number,
		size: number = 10,
		isDeleted: boolean = false,
		query: string | null = null,
		zoneId: number | null = null,
		aggregatesIds: number[] | null = null,
		stopId: number | null = null,
	) {
		let params = []
		params.push(`page=${page}`)
		params.push(`size=${size}`)
		params.push(`showDeleted=${isDeleted}`)
		if (query) params.push(`query=${query}`)
		if (zoneId) params.push(`zoneId=${zoneId}`)
		if (aggregatesIds) {
			aggregatesIds.forEach((aggregateId) => {
				params.push(`aggregatesIds=${aggregateId}`)
			})
		}
		if (stopId) params.push(`stopId=${stopId}`)
		let finalParams = params.join('&')
		return this.http
			.get<GenericListWrapper<StopsModel>>(`stops/stops?${finalParams}`)
			.pipe()
	}

	updateStop(stop: StopsModel) {
		return this.http.post(`stops/edit`, stop).pipe(
			map((response) => {
				return response
			}),
		)
	}

	updateSeveralStops(stops: StopsModel[], zoneId: number) {
		return this.http
			.put(`stops/update-several?zoneId=${zoneId}`, stops)
			.pipe(
				map((response) => {
					return response
				}),
			)
	}

	deleteStop(id: number) {
		return this.http.delete<any>(`Stops/delete?stopId=${id}`, {}).pipe()
	}

	getStopByQuery(query: string, page: number, size: number) {
		return this.http
			.get<GenericListWrapper<StopsModel>>(
				// `stops/query?${query}&page=${page}&size=${size}`
				`stops/stops?size=${size}&page=${page}&query=${query}`,
			)
			.pipe()
	}

	getStopsByZoneId(
		zoneId: number | undefined | string,
		page: number,
		size: number,
	) {
		if (zoneId === undefined) zoneId = ''
		return this.http
			.get<
				GenericListWrapper<StopsModel>
			>(`stops/stops?size=${size}&page=${page}&showDeleted=false&zoneId=${zoneId}`)
			.pipe()
	}

	newStop(stopModel: StopsModel) {
		return this.http.post<number>(`stops/create/`, stopModel).pipe(
			map((response) => {
				return response
			}),
		)
	}

	newBulkStops(stopsList: any[], zoneId: number) {
		return this.http
			.post<number>(`Stops/insert-several?zoneId=${zoneId}`, stopsList)
			.pipe(
				map((response) => {
					return response
				}),
			)
	}

	insertManyStopTimeTables(
		stopTimeTables: StopTimeTableOnly[],
		stopId: number,
		destinationId: number,
	) {
		return this.http.post<any>(
			`stops/insert-time-tables?stopId=${stopId}&destinationId=${destinationId}`,
			stopTimeTables,
		)
	}

	getStopTimeTables(originId: number) {
		return this.http.get<StopTimeTableModel[]>(
			`stops/time-tables?originId=${originId}`,
		)
	}

	updateStopTimeTables(
		stopId: number,
		destinationId: number,
		timeTables: StopTimeTableOnly[],
	) {
		return this.http.post<any>(
			`stops/update-time-tables?stopId=${stopId}&destinationId=${destinationId}`,
			timeTables,
		)
	}

	deleteStopTimeTables(stopId: number) {
		return this.http.delete<any>(
			`stops/delete-time-tables?stopId=${stopId}`,
		)
	}

	getStopsWithTimeConstraints(
		date: string,
		zoneId?: number,
		origin: boolean = true,
	) {
		if (zoneId == undefined) zoneId = 0
		return this.http.get<StopsModel[]>(
			`stops/available-stops?zoneId=${zoneId}&date=${date}&origin=${origin}`,
		)
	}

	//-------------------Drivers-------------------
	getDrivers(
		page?: number,
		size?: number,
		query?: string,
		selectedFilters?: string[],
	) {
		let params = new HttpParams()

		if (query) params = params.append('query', query)
		if (page) params = params.append('page', page)
		if (size) params = params.append('size', size)

		if (selectedFilters && selectedFilters.length > 0) {
			selectedFilters.forEach((filter) => {
				params = params.append('filters', filter)
			})
		}
		return this.http
			.get<GenericListWrapper<DriverTable>>(`Driver`, { params: params })
			.pipe()
	}

	GetCategories() {
		return this.http
			.get<DriverLicenseCategory[]>(`Driver/license-categories`)
			.pipe()
	}

	GetFiles(fileId: string) {
		return this.http.get<DriverFiles>(`Driver/file/${fileId}`).pipe()
	}

	GetAllFiles(userId: string) {
		return this.http.get<DriverFiles[]>(`Driver/${userId}/files/`).pipe()
	}

	UpdateDriver(formData: FormData) {
		return this.http.put<HttpStatusCode>(`Driver`, formData).pipe(
			map((response) => {
				return response
			}),
		)
	}

	AddDriver(formData: FormData) {
		return this.http.post<string>(`Driver`, formData).pipe(
			map((response) => {
				return response
			}),
		)
	}

	DeleteDriver(id: string) {
		return this.http.delete<HttpStatusCode>(`Driver/${id}`).pipe(
			map((response) => {
				return response
			}),
		)
	}

	GetSelectedDriverVehicles(id: string) {
		return this.http
			.get<SelectedDriverVehicle[]>(`Vehicle/getVehiclesByDriverId/${id}`)
			.pipe()
	}

	GetSelectedDriverTravelHistory(id: string) {
		return this.http
			.get<SelectedDriverTravelHistory[]>(`Driver/${id}/travel-history`)
			.pipe()
	}

	GetDriverByTrip(tripId: number) {
		return this.http
			.get<DriverDetails>(`Driver/get-by-trip?tripId=${tripId}`)
			.pipe()
	}

	//-------------------Requests-------------------

	getRequests(
		page: number,
		size: number = 10,
		query: string = '',
		statusFilter: number[] = [],
		requestsToggle: boolean = true,
	) {
		let queryFilter = []
		queryFilter.push(`section=${requestsToggle}`)
		if (query != '') {
			queryFilter.push(`query=${query}`)
		}
		if (statusFilter.length > 0) {
			queryFilter.push(`filter=${statusFilter.join('&filter=')}`)
		}

		return this.http
			.get<
				GenericListWrapper<RequestsModel>
			>(`requests/requests?page=${page}&size=${size}&${queryFilter.join('&')}`)
			.pipe()
	}

	// Used in client side "My trips"
	getOwnRequests(
		page: number,
		size: number,
		userEmail: string,
		query: string = '',
	) {
		return this.http
			.get<
				GenericListWrapper<RequestsModel>
			>(`requests/history/${userEmail}?page=${page}&size=${size}&query=${query}`)
			.pipe()
	}

	// // Used in user page backoffice
	// getRequestsHistory(userEmail: string, page: number) {
	// 	return this.http
	// 		.get<
	// 			GenericListWrapper<RequestsModel>
	// 		>(`requests/user/userEmail=${userEmail}&page=${page}`)
	// 		.pipe()
	// }

	updateRequest(request: RequestsModelToAPI) {
		return this.http.post<RequestsModel>(`requests/edit`, request).pipe(
			map((response) => {
				return response
			}),
		)
	}

	deleteRequest(id: number) {
		return this.http
			.delete<any>(
				`requests/delete?requestId=${id}&user_id=${this.envSer.getUserId()}`,
			)
			.pipe()
	}

	deleteOwnRequest(id: number) {
		return this.http
			.post<any>(`requests/delete-my-request?requestId=${id}`, {})
			.pipe()
	}

	newRequest(
		date: string,

		stop_origin: string,
		stop_destination: string,

		seats_no: number,
		specialRegime: number,
		status: number,
		firstName: string,
		lastName: string,
		email: string,
		phone: string,
		cost: number,
		distance: number,
	) {
		return this.http
			.post<string>(`requests/add-request`, {
				date: date,
				originId: stop_origin,
				destinationId: stop_destination,

				created_by: this.envSer?.getUserId(),

				seats_no: seats_no,
				specialRegime: specialRegime,
				status: status,

				firstName: firstName,
				lastName: lastName,
				email: email,
				phone: phone,
				cost: cost,
				distance: distance,
			})
			.pipe(
				map((response) => {
					return response
				}),
			)
	}

	newDriverRequest(
		date: string,

		stop_origin: string,
		stop_destination: string,

		seats_no: number,
		specialRegime: number,
		status: number,
		firstName: string,
		lastName: string,
		email: string,
		phone: string,
		cost: number,
		distance: number,
	) {
		return this.http
			.post<number>(`requests/driver/add-request`, {
				date: date,
				originId: stop_origin,
				destinationId: stop_destination,

				created_by: this.envSer?.getUserId(),

				seats_no: seats_no,
				specialRegime: specialRegime,
				status: status,

				firstName: firstName,
				lastName: lastName,
				email: email,
				phone: phone,
				cost: cost,
				distance: distance,
			})
			.pipe(
				map((response) => {
					return response
				}),
			)
	}

	updateRequestStatus(
		requestId: number,
		status: RequestStatus,
		rejectionMessage?: string | null,
	) {
		if (rejectionMessage == undefined) rejectionMessage = null
		return this.http
			.put<any>(
				`requests/changeStatus?requestId=${requestId}&status=${status}&rejectionMessage=${rejectionMessage}`,
				null,
			)
			.pipe()
	}

	driverRejectRequest(requestId: number, rejectionMessage?: string | null) {
		return this.http
			.put<any>(
				`requests/driver/cancel-request/${requestId}?rejectionMessage=${rejectionMessage}`,
				null,
			)
			.pipe()
	}

	getOperatorAndDriverByTrip(tripId: number) {
		return this.http.get<any>(`requests/operator-driver/${tripId}`).pipe()
	}

	getOperatorByDriverId(driverId: string) {
		return this.http
			.get<OperatorDataModel>(
				`operator/operator-by-driver?driverId=${driverId}`,
			)
			.pipe()
	}

	getRequestById(requestId: number) {
		return this.http
			.get<RequestsModel>(`requests/getRequest/${requestId}`)
			.pipe()
	}

	getDriverRequests(
		page: number,
		size: number = 10,
		query: string = '',
		statusFilter: number[] = [],
	) {
		let queryFilter = []
		if (query != '') {
			queryFilter.push(`query=${query}`)
		}
		if (statusFilter.length > 0) {
			queryFilter.push(`filter=${statusFilter.join('&filter=')}`)
		}

		return this.http
			.get<
				GenericListWrapper<RequestsModel>
			>(`requests/driver/${this.envSer.getUserId()}?page=${page}&size=${size}&${queryFilter.join('&')}`)
			.pipe()
	}

	// --------------------------------------------------------------------------------------------
	// --------------------------------- Users ----------------------------------------------------
	// --------------------------------------------------------------------------------------------

	getUsers(
		page?: number,
		size?: number,
		query?: string,
		selectedStates?: string[],
		selectedRoles?: string[],
	) {
		let params = new HttpParams()

		if (page) params = params.append('page', page)
		if (size) params = params.append('size', size)
		if (query) params = params.append('query', query)

		if (selectedStates && selectedStates.length > 0) {
			selectedStates.forEach((filter) => {
				params = params.append('selectedStates', filter)
			})
		}

		if (selectedRoles && selectedRoles.length > 0) {
			selectedRoles.forEach((filter) => {
				params = params.append('selectedRoles', filter)
			})
		}

		return this.http
			.get<
				GenericListWrapper<UsersModel>
			>(`users/get-all-users`, { params: params })
			.pipe()
	}

	getUser(userId: string) {
		return this.http.get<UsersModel>(`users/get-user/${userId}`).pipe()
	}

	createUser(user: FormData) {
		return this.http.post<any>('users/create-user', user)
	}

	updateUser(user: FormData) {
		return this.http.put<any>('users/update-user', user)
	}

	deleteUser(userId: string) {
		return this.http.post<any>(`users/delete-user/${userId}`, {})
	}

	reactivateUser(userId: string) {
		return this.http.post<any>(`users/reactivate-user/${userId}`, {})
	}

	getUserLicences(userId: string) {
		return this.http
			.get<
				DriverLicenseCategoryIncoming[]
			>(`users/user-licenses/${userId}`)
			.pipe()
	}

	getDriverFiles(userId: string) {
		return this.http.get<DriverFiles[]>(`users/${userId}/files`).pipe()
	}

	getUserRequestsHistory(userEmail: string, page: number) {
		return this.http
			.get<
				GenericListWrapper<UserRequestsModel>
			>(`users/requests?userEmail=${userEmail}&page=${page}`)
			.pipe()
	}

	getUserTripsHistory(userEmail: string, page: number) {
		return this.http
			.get<
				GenericListWrapper<UserRequestsModel>
			>(`users/trips?userEmail=${userEmail}&page=${page}`)
			.pipe()
	}

	createTimesheet(timesheetData: CreateUserTimesheetModel) {
		return this.http.post<any>('users/create-timesheet', timesheetData)
	}

	getTimesheetRecordById(timesheetRecordId: number) {
		return this.http
			.get<UserTimesheetModel>(
				`users/timesheetRecord/${timesheetRecordId}`,
			)
			.pipe()
	}

	getUserTimesheet(userId: string) {
		return this.http
			.get<UserTimesheetModel[]>(`users/timesheet/${userId}`)
			.pipe()
	}

	deleteTimesheetRecordById(timesheetRecordId: number) {
		return this.http
			.delete<any>(`users/delete-timesheet/${timesheetRecordId}`)
			.pipe()
	}

	editTimesheetRecordById(timesheetData: UpdateUserTimesheetModel) {
		return this.http.put<any>('users/edit-timesheet', timesheetData)
	}

	getDriverZonesTimes(userId: string, weekDay: number) {
		return this.http
			.get<
				TimeRange[]
			>(`users/driver-zones-times?userId=${userId}&weekDay=${weekDay}`)
			.pipe()
	}

	// --------------------------------------------------------------------------------------------
	// --------------------------------- Zones ----------------------------------------------------
	// --------------------------------------------------------------------------------------------

	getAllZones(page: number, size: number = 10) {
		return this.http
			.get<
				GenericListWrapper<ZonesModel>
			>(`zone/zones?page=${page}&num=${size}`)
			.pipe()
	}

	getZonesByQuery(query: string, size: number, id?: number) {
		var queries: string[] = []

		if (query != '') {
			queries.push(`&query=${query}`)
		}

		if (id != null) {
			queries.push(`&id=${id}`)
		}

		return this.http
			.get<
				GenericListWrapper<ZonesModel>
			>(`zone/zones?num=${size}${queries.join('&')}&isDeleted=false`)
			.pipe()
	}

	getZoneById(id: number) {
		return this.http
			.get<GenericListWrapper<ZonesModel>>(`zone/zones?id=${id}`)
			.pipe()
	}

	updateZone(zone: ZonesModel) {
		return this.http.put(`zone/update-zone/`, zone)
	}

	deleteZone(id: number) {
		return this.http.delete<any>(`zone/delete-zone?id=${id}`).pipe()
	}

	// Get zones by given aggregate ID through query
	getZonesByAggregateId(aggregateId: number | string, page = 0, size = 10) {
		if (aggregateId === undefined) aggregateId = ''
		return this.http
			.get<
				GenericListWrapper<ZonesModel>
			>(`zone/zones-by-aggregate?aggregateId=${aggregateId}`)
			.pipe()
	}

	getZones(
		page: number = 10,
		size: number = 10,
		id?: number,
		query?: string,
		isDeleted: boolean = false,
		aggregateIds?: number[],
	) {
		let aggregates = ''
		if (aggregateIds) {
			aggregates = aggregateIds
				.map((aggregateId) => `aggregatesIds=${aggregateId}`)
				.join('&')
		}

		return this.http
			.get<
				GenericListWrapper<ZonesModel>
			>(`zone/all-zones?page=${page}&size=${size}&isDeleted=${isDeleted}&query=${query}&${aggregates}`)
			.pipe()
	}

	newZone(zone: {
		name: any
		description: any
		aggregateId: any
		color: any
		areaDelimiters: any
	}) {
		return this.http.post<ZonesModel>(`zone/create-zone/`, zone)
	}

	getAllStopsByZoneId(zoneId: number) {
		return this.http
			.get<
				GenericListWrapper<StopsModel>
			>(`stops/stops?zoneId=${zoneId}&size=1000`)
			.pipe()
	}

	setZoneShapeCoordinates(zoneId: number, coordinates: string[]) {
		return this.http
			.put<any>(
				`zone/update-shape-coordinates?zoneId=${zoneId}`,
				coordinates,
			)
			.pipe()
	}

	getZoneShapeCoordinates(zoneId: number) {
		return this.http
			.get<string[]>(`zone/get-shape-coordinates?zoneId=${zoneId}`)
			.pipe()
	}

	getZoneTimeTable(zoneId: number) {
		return this.http.get<any>(`zone/timetable?zoneId=${zoneId}`).pipe()
	}

	insertManyZoneTimeTables(timeTables: TimeTable[]) {
		return this.http.put<any>(`zone/insert-timetable`, timeTables).pipe()
	}

	updateManyZoneTimetables(timetables: TimeTable[]) {
		return this.http.post<any>(`zone/update-timetable`, timetables).pipe()
	}

	deleteManyZoneTimetables(timetableIds: number[]) {
		return this.http
			.delete<any>(`zone/delete-many-timetables`, { body: timetableIds })
			.pipe()
	}

	getZoneDrivers(zoneId: number) {
		return this.http.get<any[]>('zone/drivers?zoneId=' + zoneId).pipe()
	}

	insertManyZoneDrivers(zoneDrivers: ZoneDriverInsert[]) {
		return this.http.put('zone/associate-many-drivers', zoneDrivers).pipe()
	}

	deleteManyZoneDrivers(ids: string[], zoneId: number) {
		return this.http
			.delete<any>(`zone/desassociate-drivers?zoneId=${zoneId}`, {
				body: ids,
			})
			.pipe()
	}

	// --------------------------------------------------------------------------------------------
	// --------------------------------- Aggregates -----------------------------------------------
	// --------------------------------------------------------------------------------------------

	// Delete aggregate
	deleteAggregate(aggregateId: number) {
		return this.http
			.delete<any>(`Aggregate/delete-aggregate?id=${aggregateId}`)
			.pipe()
	}

	// Get all aggregates
	getAggregates(page: number, size: number) {
		return this.http
			.get<
				GenericListWrapper<AggregateModel>
			>(`Aggregate/aggregates?page=${page}&size=${size}`)
			.pipe()
	}

	// Get aggregates by query
	getAggregatesByQuery(query: string, size: number) {
		var queries: string[] = []
		if (query != '') {
			queries.push(`&query=${query}`)
		} else {
			queries = []
		}

		return this.http
			.get<
				GenericListWrapper<AggregateModel>
			>(`Aggregate/aggregates?num=${size}${queries.join('&')}&isDeleted=false`)
			.pipe()
	}

	// Update aggregate
	updateAggregate(aggregate: AggregateModel) {
		return this.http.put(`Aggregate/update-aggregate`, aggregate).pipe(
			map((response) => {
				return response
			}),
		)
	}

	// Create new aggregate
	newAggregate(aggregate: AggregateModel) {
		return this.http
			.post<{
				message: string
				id: number
			}>(`Aggregate/create-aggregate`, aggregate)
			.pipe(
				map((response) => {
					return response
				}),
			)
	}

	//---------------map------------------------
	getCoordinates(street: string, parish: string) {
		return this.http
			.get<any>(
				`https://geocode.maps.co/search?street=${street}&city=${parish}&country=PT&api_key=${environment.geocodingApiKey}`,
			)
			.pipe()
	}

	getStreet(lat: number, lon: number) {
		return this.http
			.get<any>(
				`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`,
			)
			.pipe()
	}

	// --------------------------------------------------------------------------------------------
	// --------------------------------- Vehicle -----------------------------------------------
	// --------------------------------------------------------------------------------------------

	newVehicle(newVehicleFormData: FormData) {
		return this.http
			.post<number>(`Vehicle/createVehicle`, newVehicleFormData)
			.pipe(
				map((response) => {
					return response
				}),
			)
	}

	updateVehicle(editVehicleFormData: FormData) {
		return this.http
			.post<number>(`Vehicle/editVehicle`, editVehicleFormData)
			.pipe(
				map((response) => {
					return response
				}),
			)
	}

	deleteVehicle(id: number) {
		return this.http.post<any>(`Vehicle/deleteVehicle/${id}`, {}).pipe()
	}

	getVehicles(
		query?: string,
		page?: number,
		size?: number,
		showInactive: boolean = false,
		fuelTypes: number[] = [],
	) {
		if (query == undefined) query = ''

		let fuelTypesQuery =
			fuelTypes.length != 0
				? 'fuelTypes=' + fuelTypes.join('&fuelTypes=')
				: ''
		return this.http
			.get<
				GenericListWrapper<VehicleDataModel>
			>(`Vehicle?query=${query}&page=${page}&size=${size}&showInactive=${showInactive}&${fuelTypesQuery}`)
			.pipe()
	}

	getVehicleFile(fileId: string) {
		return this.http
			.get<VehicleFiles>(`Vehicle/getVehicleFile/${fileId}`)
			.pipe()
	}

	getAllVehicleFiles(vehicleId: number) {
		return this.http
			.get<VehicleFiles[]>(`Vehicle/getAllVehicleFiles/${vehicleId}`)
			.pipe()
	}

	getVehicleByDriverId(driverId: string) {
		return this.http
			.get<any>(`Vehicle/getVehiclesByDriverId/${driverId}`)
			.pipe()
	}

	// --------------------------------------------------------------------------------------------
	// --------------------------------- Profile -----------------------------------------------
	// --------------------------------------------------------------------------------------------
	getProfile(userId: string) {
		return this.http
			.get<UpdateProfileModel>(`users/getProfile/${userId}`)
			.pipe()
	}

	updateProfile(profileData: UpdateProfileModel) {
		return this.http.put<any>('users/updateProfile', profileData)
	}

	deleteProfile() {
		return this.http.delete<any>(`users/delete-account`)
	}

	// Popular routes
	getPopularRoutes(size: number) {
		return this.http.get<any>(`requests/popular-routes?size=${size}`).pipe()
	}
	// --------------------------------------------------------------------------------------------
	// --------------------------------- Notifications -----------------------------------------------
	// --------------------------------------------------------------------------------------------
	getNotifications(
		userId: string,
		page: number,
		size: number,
		alsoGetRead: boolean,
		notificationType: string = '',
		query: string = '',
	) {
		let notificationTypeQuery = ''
		let queryQuery = ''

		if (notificationType != '') {
			notificationTypeQuery = `&notificationType=${notificationType}`
		}

		if (query != '') {
			queryQuery = `&query=${query}`
		}

		return this.http
			.get<
				GenericListWrapper<NotificationObject>
			>(`Notification/notifications?userId=${userId}&page=${page}&size=${size}&read=${alsoGetRead}${notificationTypeQuery}${queryQuery}`)
			.pipe()
	}

	getAllNotifications(
		userId: string,
		page: number,
		size: number = 10,
		notificationType: string = '',
		query: string = '',
		isRead: boolean | null = null,
	): Observable<GenericListWrapper<NotificationObject>> {
		return this.http.get<GenericListWrapper<NotificationObject>>(
			`Notification/get-all-notifications?userId=${userId}&page=${page}&size=${size}&notificationType=${notificationType}&query=${query}&isRead=${isRead}`,
		)
	}

	deleteNotification(notificationId: number) {
		return this.http.delete(`Notification/${notificationId}`).pipe()
	}

	deleteAllNotifications(userId: string): Observable<any> {
		return this.http.delete(`Notification/delete-all/${userId}`)
	}

	updateNotification(id: number, notification: UpdateNotification) {
		return this.http.put(`Notification/update/${id}`, notification).pipe()
	}

	// Customer support
	sendCustomerSupportEmail(model: CustomerSupportMail) {
		// Get the current language from local storage
		let language = localStorage.getItem('language')

		// Get the language case it is stored in the local storage
		language == null ? (language = '') : (language = `?lang=${language}`)

		return this.http.post(`Support/email${language}`, model).pipe()
	}

	// --------------------------------------------------------------------------------------------
	// --------------------------------- PricesPerKms -----------------------------------------------
	// --------------------------------------------------------------------------------------------

	getPricesPerKms() {
		return this.http
			.get<
				GenericListWrapper<PricesPerKms>
			>(`PricesPerKm/GetAll?page=0&size=1000`)
			.pipe()
	}

	updatePricesPerKms(newModel: PricesPerKms) {
		return this.http.put(`PricesPerKm/Update`, newModel).pipe()
	}

	deletePricesPerKms(id: number) {
		return this.http.delete(`PricesPerKm?modelId=${id}`).pipe()
	}

	insertNewPricePerKm(price: PricesPerKms) {
		price.id = undefined
		return this.http.put(`PricesPerKm/Insert`, price).pipe()
	}

	calculateRoutePrice(distance: number) {
		return this.http
			.get<number>(`PricesPerKm/GetRoutePrice/${distance}`)
			.pipe()
	}

	// --------------------------------------------------------------------------------------------
	// --------------------------------- Operators -----------------------------------------------
	// --------------------------------------------------------------------------------------------

	getOperators(
		query?: string,
		page?: number,
		size?: number,
		selectedOperatorStateFilters?: string[],
	) {
		let params = new HttpParams()

		if (query) params = params.append('query', query)
		if (page) params = params.append('page', page)
		if (size) params = params.append('size', size)
		if (
			selectedOperatorStateFilters &&
			selectedOperatorStateFilters.length > 0
		) {
			selectedOperatorStateFilters.forEach((filter) => {
				params = params.append('selectedOperatorFilters', filter)
			})
		}

		return this.http
			.get<
				GenericListWrapper<OperatorDataModel>
			>(`Operator`, { params: params })
			.pipe()
	}

	getOperatorById(id: number) {
		return this.http.get<OperatorDataModel>(`Operator/get-by-id/${id}`)
	}

	newOperator(newOperatorFormData: FormData) {
		return this.http
			.post<number>(`Operator/createOperator`, newOperatorFormData)
			.pipe(
				map((response) => {
					return response
				}),
			)
	}

	deleteOperator(operatorId: number) {
		return this.http
			.post<any>(`Operator/deleteOperator?operatorId=${operatorId}`, {})
			.pipe()
	}

	updateOperator(editOperator: FormData) {
		return this.http.post(`Operator/editOperator`, editOperator).pipe(
			map((response) => {
				return response
			}),
		)
	}

	// --------------------------------------------------------------------------------------------
	// --------------------------------- Operator Drivers -----------------------------------------
	// --------------------------------------------------------------------------------------------

	getOperatorDrivers(operatorId: number, page?: number, size?: number) {
		let params = new HttpParams()

		if (page) params = params.append('page', page)
		if (size) params = params.append('size', size)
		params = params.append('operatorId', operatorId)

		return this.http.get<GenericListWrapper<DriverDetails>>(
			`Operator/operatorDrivers`,
			{ params: params },
		)
	}

	isDriverAssigned(driverId: string) {
		let params = new HttpParams()

		params = params.append('driverId', driverId)

		return this.http
			.get<any>(`Operator/isDriverAssigned`, { params: params })
			.pipe()
	}

	removeDriverAssign(operatorDriverId: string) {
		return this.http
			.delete(
				`Operator/removeDriverAssign?operatorDriverId=${operatorDriverId}`,
			)
			.pipe()
	}

	// --------------------------------------------------------------------------------------------
	// --------------------------------- Payments -------------------------------------------------
	// --------------------------------------------------------------------------------------------

	getPayments(
		page: number,
		size: number = 10,
		query: string = '',
		filter: boolean[],
		loadAll: boolean = false,
	) {
		let queryFilter = []
		if (filter.length > 0) {
			queryFilter.push(`filter=${filter.join('&filter=')}`)
		}

		return this.http.get<GenericListWrapper<TripsModel>>(
			`Payments?${queryFilter.join('&')}&page=${page}&size=${size}&query=${query}&loadAll=${loadAll}`,
		)
	}

	validatePayment(tripId: number, variableRate: number) {
		return this.http.put<boolean>(
			`Payments/${tripId}/validate?VariableRate=${variableRate}`,
			{},
		)
	}

	getDriverPayments(
		page: number,
		size: number = 10,
		query: string = '',
		filter: boolean[],
		loadAll: boolean = false,
	) {
		let queryFilter = []
		if (filter.length > 0) {
			queryFilter.push(`filter=${filter.join('&filter=')}`)
		}

		return this.http.get<GenericListWrapper<TripsModel>>(
			`Payments/driver?${queryFilter.join('&')}&page=${page}&size=${size}&query=${query}&loadAll=${loadAll}`,
		)
	}
	// --------------------------------------------------------------------------------------------
	// --------------------------------- Trips------------ ----------------------------------------
	// --------------------------------------------------------------------------------------------
	public getAllTrips(
		page: number = 0,
		size: number = 10,
		query: string,
		filter: number[],
	) {
		let queryFilter = []
		if (filter.length > 0) {
			queryFilter.push(`filter=${filter.join('&filter=')}`)
		}

		return this.http.get<GenericListWrapper<TripsModel>>(
			`Trips?${queryFilter.join('&')}&page=${page}&size=${size}&query=${query}`,
		)
	}

	public updateTripStatus(
		tripId: number,
		status: TripStatus,
		rejectionMessage?: string | null,
	) {
		if (rejectionMessage == undefined) rejectionMessage = null
		return this.http.put<HttpStatusCode>(
			`Trips/${tripId}/changeStatus?status=${status}&rejectionMessage=${rejectionMessage}`,
			null,
		)
	}

	public driverRejectTrip(tripId: number, rejectionMessage: string) {
		return this.http.put<HttpStatusCode>(
			`Trips/${tripId}/reject?rejectionMessage=${rejectionMessage}`,
			null,
		)
	}

	getTripById(tripId: number) {
		return this.http.get<TripsModel>(`Trips/getTrip/${tripId}`).pipe()
	}

	public getDriverTrips(
		page: number = 0,
		size: number = 10,
		query: string,
		filter: number[],
	) {
		let queryFilter = []
		if (filter.length > 0) {
			queryFilter.push(`filter=${filter.join('&filter=')}`)
		}

		return this.http.get<GenericListWrapper<TripsModel>>(
			`Trips/driver/${this.envSer.getUserId()}?${queryFilter.join('&')}&page=${page}&size=${size}&query=${query}`,
		)
	}

	getTripStops(tripId: number) {
		return this.http.get<TripStops[]>(`Trips/trip-stops?tripId=${tripId}`)
	}

	// --------------------------------------------------------------------------------------------
	// --------------------------------- Miscellaneous -----------------------------------------
	// --------------------------------------------------------------------------------------------

	public getDistanceBetweenTwoPoints(coords: string[]) {
		return firstValueFrom(
			this.http
				.get<any>(
					`${environment.osrmServerUrl}/driving/${coords.join(';')}`,
				)
				.pipe(map((response) => response.routes[0].distance)),
		)
	}
}
